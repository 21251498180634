.ordering-main{

  .ordering-header{
    height: 25vh;
    width: 100%;
    background: url("../../assets/images/temp/restaurant.jpg") center;
    display: flex;
    background-size: cover;
    flex-direction: column;
    justify-content: flex-end;
    position: relative;
    border-radius: 10px;


    .back-button{
      display: inline-block;
      top: 30px;
      position: absolute;
      left: 15px;
      background: #00000073;
      height: 35px;
      border-radius: 5px;
      padding: 5px;
    }

  }

  .ordering-profile-pic-container{
    //background: url("../../assets/images/1.png") no-repeat bottom;
    background-size: contain;
    .order-profile-pic{
      background: url("../../assets/images/temp/profile-pic.jpg") center;
      height: 90px;
      width: 90px;
      border: 5px solid #FFFFFF;
      display: block;
      border-radius: 20px;
      background-size: cover;
      margin-bottom: -50px;
      background-color: #000;
    }
  }

  .ordering-title{
    background-color: #FFFFFF;
    margin-top: -2px;
    padding-top: 12px;
    position: relative;
    background: transparent;
    z-index: 999;
  }

  .title-text-1{
    color: #1D1D1D;
    font-family: Poppins;
    font-size: 16px;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
  }


  .title-text-2{
    color: #1D1D1D;
    font-family: Poppins;
    font-size: 12px;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
    opacity: 0.4;
  }

}

.padding-left-right-5{
  padding: 0 10px;
}


.restaurant-title-container{
  padding-left: 110px;
  display: flex;
  justify-content: space-between;
  .dropdown-button{
    padding: 5px;
    background: #D9D9D9;
    height: 34px;
    border-radius: 5px;
    svg{
      transform: rotate(0deg);
      transition: 0.5s;
    }
    &.dropdown_clicked{
      svg{
        transform: rotate(180deg);
      }
    }
  }
}

.app-restaurent-detail-panel{
  max-height: 100px;
    transition: 0.5s;
    overflow: hidden;
    &.hide_detail_panel{
      max-height: 0;
    }
}


.ordering-main-title{
  display: flex;
  justify-content: space-between;
  padding: 35px 20px 15px 20px;
  .back-button{
    svg{
      path{
        fill : black;
      }
    }
  }
}




.dish-content-container .dis-discription-parent .dish-content-description{
  p{

    &:nth-child(1){

      margin: 10px 0;
      transition: 0.5s;
    }

  }
}


.dish-content-container .dis-discription-parent.limit_height .dish-content-description{
  p{
    &:nth-child(1){

      margin: 0;
      padding: 0;
    }
  }
}