.del-method-select{
    .delivery-select{
        display: flex;
        background-color: #d9d9d9;
        border-radius: 10px;
      }
      .delivery-select-buttons{
        display: flex;
        font-family: "Exo",sans-serif;
        font-weight: bold;
        font-size: 12px;
      
      
      
        &:first-child{
          .delivery-select-icon{
            padding-left: 15px;
          }
      
        }
      
        &:last-child{
          .delivery-select-icon{
            padding-right: 10px;
          }
        }
      
      
      
        &.delivery-selected{
          height: 52px;
          background-color: #F3EC18;
          padding: 0 10px;
          border-radius: 10px;
      
          .delivery-select-icon{
            display: none;
          }
      
      
          .delivery-select-content{
            display: flex;
          }
      
        }
      
        .delivery-select-content{
          display: none;
          align-items: center;
        }
      
        .delivery-select-icon{
          display: flex;
          align-items: center;
          padding: 0 15px;
          font-size: 18px;
      
        }
      
      
      
      }
      
}