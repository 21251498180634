.round-button{
  height: 48px;
  width: 48px;
  display: flex;
  background: var(--sk-color-secondory);
  justify-content: center;
  align-items: center;
  border-radius: 100%;
}


.button-1{
  border-radius: 100px;
  background: #F3EC18;
  height: 40px;
  text-align: center;
  color: #000;
  font-size: 14px;
  font-style: normal;
  font-weight: 700;
  min-width: 100px;
}

.link1{
  color: rgba(0, 0, 0, 0.25);
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
}



.link2{
  color: #D36A28;
  font-family: Exo;
  font-size: 14px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
  text-decoration: none;
}

.login-button-container{

  margin-top: 12px;
  display: flex;


  .login-container-first{
    flex: 1;
    text-align: left;
    display: flex;
    align-items: center;
  }

}
