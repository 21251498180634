.form-input-container{
  position: relative;
  margin-top: 12px;

  &:nth-child(1){
    margin-top: 0;
  }

  .form-input-icon{
    position: absolute;
    top: 50%;
    left: 15px;
    width: 20px;
    height: 20px;
    transform: translateY(-50%);

    svg{
      width: 18px;
    }
  }

  .form-input{
    background: #F2F2F2;
    border: 1px solid #EDEDED;
    border-radius: 100px;
    height: 40px;
    width: 100%;
    padding-left: 45px;
    font-size: 12px;
    font-weight: 500;
  }


}
