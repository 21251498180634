:root{

  --sk-color-primary : #1D1D1D;
  --sk-color-secondory : #F3EC18;

}

@import "buttons";
@import "form";
@import "ordering-main";
@import "delivary_method_select";

.home-container{

  padding:  0 25px;
  font-family: exo;

  .round-button{
    margin-left: 10px;
  }

}

.main-container{
  padding: 0 20px;
}

.flex{
  display: flex;
}
.flex-1{
  flex: 1;
}


.relative {
  position: relative;
}



ion-input.main-input {
  input {
    width: 100%;
    height: 48px;
    border-radius: 100px;
    border: none;
    font-size: 14px;
    font-weight: 600;
    padding-left: 40px;
  }

  &.input-new input{
    width: 100%;
    height: 48px;
    border-radius: 100px;
    border: none;
    font-size: 12px !important;
    font-weight: 600 !important;
    padding-right: 40px !important;
    white-space: nowrap !important;
    overflow: hidden;
    text-overflow: ellipsis !important;
  }
  
}




.closed_notification{

  margin-top: 10px;
  font-family: 'exo';
  position: sticky;
  top: 0;
  z-index: 999999;

  .open-time-note{
    width: 80%;
    margin-left: auto !important;
    margin-right: auto !important;
    background-color: transparent !important;
    min-height: 40px !important;
    padding: 0 !important;
    border-radius: 0 !important;
    color: #ffffff !important;
    font-weight: 800 !important;
    font-size: 14px;
  }


  $black: #66615B;
$grey: #ccc;
$white: #fff;
$yellow: #ff0;
$green: #8bc34a;
$blue: #29d2e4;
$orange: #f87d09;
$dark-pink: #d72c1e;
$purple: #9c27b0;
$light-purple: #efedff;
//Body
$main-background: $light-purple;


@mixin alert-variant($alert-vertical-padding,
$alert-horizontal-padding,
$background,
$border-color,
$text-color) {
  background-color: $background;
  border: 1px solid $border-color;
  color: $text-color;
  padding: $alert-vertical-padding $alert-horizontal-padding;
  .alert-icon {
    float: left;
    margin-right: 15px;
    svg{
    height: 60px;
    }
  }
  .alert-info {
    margin: 0 10px 0 0;
    text-transform: uppercase;
    font-weight: normal;
    font-size: 14px;
  }
  .alert-container {
    position: relative;
    max-width: 960px;
    margin: 0 auto;
  }
  .close-icon {
    float: right;
    color: #000;
    margin-top: 0;
    margin-left: 0;
    width: 21px;
    height: 21px;
    position: relative;
    background: none;
    border: none;
    outline: none;
    cursor: pointer;
    text-indent: -999px;
    overflow: hidden;
    white-space: nowrap;
    &:before,
    &:after {
      content: '';
      position: absolute;
      top: 8px;
      width: 15px;
      height: 2px;
      left: 0;
    }
    &:before {
      background-color: #fff;
      transform: rotate(-45deg);
    }
    &:after {
      background-color: #fff;
      transform: rotate(45deg);
    }
  }
}

.alert {
  @include alert-variant(15px,
  20px,
  $blue,
  $blue,
  #fff);
  &.alert-custom {
    @include alert-variant(null,null,$black,$black,null);
  }
  &.alert-info {
    @include alert-variant(null,null,$blue,$blue,null);
  }
  &.alert-success {
    @include alert-variant(null,null,$green,$green,null);
  }
  &.alert-warning {
    @include alert-variant(null,null,$orange,$orange,null);
  }
  &.alert-danger {
    @include alert-variant(null,null,$dark-pink,$dark-pink,null);
  }
}

}


.item-center-flex{
    display: flex;
    align-items: center;
}


.food-list-row{
    display: flex;
    flex-wrap: wrap;
}